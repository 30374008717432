<template>
  <div>
    <v-app-bar v-if="$vuetify.breakpoint.mobile" flat prominent color="white">
      <v-container class="pa-0">
        <v-row no-gutters>
          <v-col cols="12" class="mb-2">
            <router-link to="/" style="text-decoration: none; color: inherit;">
              <div class="text-h4">raumcloud</div>
            </router-link>
          </v-col>
          <v-col cols="6">
            <a href="https://dfdk.de/" target="_blank">
              <img src="/dfdkweblogo.svg"  height="50" />
            </a>
            <a href="https://www.hauptsachefrei.de/de" target="_blank">
              <img src="/HF.png" height="50" class="ml-2" />
            </a>
          </v-col>
          <v-col cols="6">
            <div class="text-body-2 text-right">Eine Initiative von Hauptsache Frei und DfdK.</div>
          </v-col>
        </v-row>
        <hr style="height: 4px; background-color: #3d3d3d; border:none" />
      </v-container>
    </v-app-bar>
    
    <v-app-bar v-if="!$vuetify.breakpoint.mobile" flat height="200px" color="white">
      <v-container>
        <v-row no-gutters>
          <v-col cols="12" sm="5">
            <a href="https://dfdk.de/" target="_blank">
              <img src="/dfdkweblogo.svg" />
            </a>
            <a href="https://www.hauptsachefrei.de/de" target="_blank">
              <img src="/HF.png" height="96" class="ml-4 mt-0" />
            </a>
          </v-col>
          <v-col cols="12" sm="7">
            <router-link to="/" style="text-decoration: none; color: inherit;">
              <div class="text-h1 ma-4 text-right">raumcloud</div>
            </router-link>
          </v-col>
          <v-col cols="12">
            <div class="text-body-1 mt-2 mb-2 text-right">Eine Initiative von Hauptsache Frei und DfdK.</div>
          </v-col>
        </v-row>
        <hr style="height: 9px; background-color: #3d3d3d; border:none" />
      </v-container>
    </v-app-bar>
  </div>
</template>

<script>
  export default {
    name: 'Header',
    data: () => ({
    })
  }
</script>
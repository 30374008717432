import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import router from './router'

Vue.config.productionTip = false

import { Directus } from "@directus/sdk";
const client = new Directus("https://directus.raumcloud.com")
Object.defineProperties(Vue.prototype, {
  $client: { value: client }
})

new Vue({
  vuetify,
  router,
  render: h => h(App)
}).$mount('#app')

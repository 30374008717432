<template>
  <v-card v-if="room" tile flat class="my-4">
    <v-row>
      <v-col cols="12" sm="4">
        <router-link :to="'/room/' + room.id">
          <v-img v-if="room.background_image" :src="'https://directus.raumcloud.com/assets/' + room.background_image.id" class="white--text align-end" gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)" height="200px">
            <v-card-title v-text="room.name"></v-card-title>
          </v-img>
          <v-img v-else src="/panos-sakalakis-63sI4HO30tw-unsplash.jpg" class="white--text align-end" gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)" height="200px">
            <v-card-title v-text="room.name"></v-card-title>
          </v-img>
        </router-link>
        <v-card-text class="text-subtitle-2 text-sm-h6">
          <span v-if="room.area">{{ room.area }} m²<br /></span>
          <span v-if="room.rent_hour">{{ room.rent_hour }} €/Stunde<br /></span>
          <span v-if="room.rent_day">{{ room.rent_day }} €/Tag</span>
        </v-card-text>
      </v-col>
      <v-col cols="12" sm="8">
        <v-card-text>
          <v-row>
            <v-col cols="12" dm="6">
              Nutzung<br />
              <v-chip small label class="ma-1" v-for="(tag, index) in room.tags_usage" :key="index" :color="tag.tags_usage_id.color">{{tag.tags_usage_id.name}}</v-chip>
            </v-col>
            <v-col cols="12" dm="6">
              Ausstattung<br />
              <v-chip small label class="ma-1" v-for="(tag, index) in room.tags_equipment" :key="index" :color="tag.tags_equipment_id.color">{{tag.tags_equipment_id.name}}</v-chip>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-text v-html="room.description" />
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
  export default {
    name: 'RoomCard',
    props: ['room'],
    data: () => ({
    })
  }
</script>

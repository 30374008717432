<template>
  <v-footer color="#3d3d3d" class="text-center white--text" padless>
    <v-row justify="center" no-gutters>
      <v-col cols="12" sm="3" class="pa-4 text-left">
        <strong>Förderung</strong>
        <p class="text-body-2" style="text-align:justify">
          Die Raumcloud ist Teil des Projekts DOCK Hamburg, eine Initiative von Hauptsache Frei und DfdK. DOCK Hamburg wird gefördert vom Fonds Darstellende Künste aus Mitteln der Beauftragten der Bundesregierung für Kultur und Medien.
        </p>
        <v-row no-gutters>
          <v-col cols="8">
            <img src="/DAKU_Logokombi_05.svg" height="80" />
          </v-col>
          <v-col cols="4">
            <!--<img src="/HF.png" height="80" class="ml-2" />-->
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" sm="3" class="pa-4 text-left">
        <strong>Kontakt</strong>
        <p>
          Dachverband freie darstellende Künste Hamburg e.V.<br>
          Wartenau 16<br>
          22089 Hamburg<br>
          Telefon: 040.46 00 65 95<br>
          <a href="mailto:raum@dfdk.de">raum@dfdk.de</a>
        </p>
      </v-col>
      <v-col cols="12" sm="3" class="pa-4 text-left">
        <strong>Infos & Service</strong>
        <div v-for="(link, index) in links" :key="index">
          <a :href="link.link" class="white--text" style="text-decoration:none" target="_blank">{{link.title}}</a>
        </div>
      </v-col>
      <v-col class="py-4" cols="12" style="border-top: 1px white solid">
        Copyright © 2021 DfdK – Dachverband freie darstellende Künste Hamburg e.V.. Alle Rechte vorbehalten.
      </v-col>
    </v-row>
  </v-footer>
</template>

<script>
  export default {
    name: 'Footer',
    data: () => ({
       links: [
        {'title':'Was ist?', 'link': '/wasist'},
        {'title':'Ich habe einen Raum!', 'link': 'mailto:?to=raum@dfdk.de&subject=Ich%20habe%20einen%20Raum&body=Hallo%20Raumcloud-Team%2C%0A%0Aich%20habe%20einen%20Raum%20und%20w%C3%BCrde%20gerne%20mitmachen.%0A%0AHier%20mehr%20Details%3A%0A%0A%0A%0A%0AViele%20Gr%C3%BC%C3%9Fe%0A'},
        {'title':'DfdK', 'link': 'https://dfdk.de'},
        {'title':'Hauptsache Frei', 'link': 'https://www.hauptsachefrei.de/de'},
        {'title':'Datenschutz', 'link': 'https://dfdk.de/datenschutz.html'},
        {'title':'Impressum', 'link': '/imprint'}
      ]
    })
  }
</script>

<template>
  <v-container>
    <b>raumcloud</b> erleichtert den Kontakt zwischen Raumanbieter:innen und Raumsuchenden im Großraum Hamburg. Beginne deine Raumsuche hier:
    <v-sheet color="#3d3d3d" class="searchBox white--text font-weight-bold text-h6 text-sm-h5 pa-4 mt-3">
      Filter
      <hr />
      <v-text-field v-model="searchTerm" placeholder="Suche …" v-on:keypress.enter="getRooms()" />

      <v-row justify="space-between">
        <v-col cols="12" sm="6">
          Nutzung Tags
          
          <v-row v-for="i in rowCountUsage" :key="i" no-gutters>
            <v-col v-for="tag in itemUsageCountInRow(i)" :key="tag.name">
              <v-checkbox :color="tag.color" class="ma-1 white--text" v-model="selectedTagsUsage" :label="tag.name" :value="tag.id" />
            </v-col>
          </v-row>

        </v-col>
        <v-col cols="12" sm="6">
          Ausstattung Tags

          <v-row v-for="i in rowCountEquipment" :key="i" no-gutters>
            <v-col v-for="tag in itemEquipmentCountInRow(i)" :key="tag.name">
              <v-checkbox :color="tag.color" class="ma-1 white--text" v-model="selectedTagsEquipment" :label="tag.name" :value="tag.id" />
            </v-col>
          </v-row>

        </v-col>
        <v-col cols="6">
          Preis pro Tag
          <v-slider v-model="pricePerDay" min="0" max="200" :hint="'<= ' + pricePerDay + ' €'" persistent-hint />
        </v-col>
        <v-col cols="6">
          Raumgröße
          <v-slider v-model="space" min="0" max="500" :hint="'>= ' + space +' m²'" persistent-hint />
        </v-col>
      </v-row>

      <v-row justify="space-between" >
        <v-col cols="5" md="2">
          <v-btn-toggle v-model="viewToggle" tile mandatory dense style="float:right;">
            <v-btn @click="switchView(false)" color="#ffd500"><v-icon>mdi-view-module</v-icon></v-btn>
            <v-btn @click="switchView(true)" color="#ffd500"><v-icon>mdi-map</v-icon></v-btn>
          </v-btn-toggle>
        </v-col>
        <v-col cols="5" md="2">
          <v-btn @click="getRooms()" color="#ffd500" :loading="seachLoading" :disabled="seachLoading" tile>Suchen</v-btn>
        </v-col>
      </v-row>
    </v-sheet>

    <v-container v-show="showAsMap" class="ma-0 pa-0">
      <div ref="map-root" style="width: 100%; height:600px; margin-top: 4px;" />
      <v-card max-height="400" width="300" id="popup" v-show="showPopup" tile color="#3d3d3d">
        <v-card-title v-if="popupRoom.institution_id" class="white--text">{{popupRoom.institution_id.name}}</v-card-title>
        <v-card-text v-if="popupRoom.institution_id" class="white--text">{{popupRoom.institution_id.rooms.length}} Räume</v-card-text>
        <v-card-text v-if="popupRoom.institution_id" class="white--text" v-html="popupRoom.institution_id.description" />
        
        <v-btn v-if="popupRoom.institution_id" tile small color="#ffd500" target="_blank" style="position:absolute; bottom: 8px; right:8px" :to="'/institution/' + popupRoom.institution_id.id">Mehr</v-btn>
      </v-card>
    </v-container>

    <v-row v-if="rooms" v-show="!showAsMap" class="my-2">
      <v-col cols="12" v-for="room in rooms" :key="room.id">
        <hr />
        <RoomCard :room="room" />
      </v-col>
    </v-row>

  </v-container>
</template>

<script>

import RoomCard from '@/components/RoomCard.vue'

import View from 'ol/View'
import Map from 'ol/Map'
import {Tile as TileLayer, Vector as VectorLayer} from 'ol/layer';
import Feature from 'ol/Feature';
import {Style, Icon} from 'ol/style';
import {OSM} from 'ol/source';
import Overlay from 'ol/Overlay';
import Point from 'ol/geom/Point';
import {fromLonLat} from 'ol/proj';
import VectorSource from 'ol/source/Vector';
import 'ol/ol.css'

function greyscale(context) {
  var canvas = context.canvas;
  var width = canvas.width;
  var height = canvas.height;
  var imageData = context.getImageData(0, 0, width, height);
  var data = imageData.data;

  for(var i=0; i<data.length; i += 4){
    var r = data[i];
    var g = data[i + 1];
    var b = data[i + 2];
    // CIE luminance for the RGB
    var v = 0.2126 * r + 0.7152 * g + 0.0722 * b;
    // Show white color instead of black color while loading new tiles:
    if(v === 0.0)
    v=255.0;  
    data[i+0] = v; // Red
    data[i+1] = v; // Green
    data[i+2] = v; // Blue
    data[i+3] = 255; // Alpha
  }
  context.putImageData(imageData,0,0);
}

export default {
  name: 'Home',
  components: {
    RoomCard,
  },
  data: () => ({
    rooms: {},
    tagItemsPerRow: 2,
    showAsMap: false,
    searchTerm: '',
    pricePerDay:0,
    space: 0,
    viewToggle: 0,
    tags_usage: [],
    tags_equipment: [],
    selectedTagsUsage:[],
    selectedTagsEquipment:[],
    firstLoad: true,
    fab: true,
    map: null,
    popupOverlay: null,
    popupRoom: {},
    showPopup: false,
    mapPOIs: [],
    seachLoading: false,
  }),
  async created() {
    let temp = await this.$client.items("tags_usage").readMany({ fields: ['*.*'], filter:{'favorite' : {_eq:true}}})
    this.tags_usage =  temp.data


    temp =  await this.$client.items("tags_equipment").readMany({ fields: ['*.*'], filter:{'favorite' : {_eq:true}}})
    this.tags_equipment = temp.data

    this.getRooms()
  },
  computed:{
    rowCountUsage() {
      return Math.ceil(this.tags_usage.length / this.tagItemsPerRow)
    },
    rowCountEquipment() {
      return Math.ceil(this.tags_equipment.length / this.tagItemsPerRow)
    }
  },
  methods: {
    itemUsageCountInRow:function(index){
     return this.tags_usage.slice((index - 1) * this.tagItemsPerRow, index * this.tagItemsPerRow)
    },
    itemEquipmentCountInRow:function(index){
     return this.tags_equipment.slice((index - 1) * this.tagItemsPerRow, index * this.tagItemsPerRow)
    },
    initMap() {
      if (this.map) return null;

      var grayOsmLayer = new TileLayer({
        source: new OSM()
      });

      grayOsmLayer.on('postrender', function(event) {
        greyscale(event.context)
      });

      this.map = new Map({target: this.$refs['map-root'],
                          layers: [
                            grayOsmLayer
                          ],
                          view: new View({
                            zoom: 11,
                            minZoom: 10,
                            maxZoom: 19,
                            center: fromLonLat([9.993682, 53.551086]),
                            constrainResolution: true
                          }),
                        })

      this.popupOverlay = new Overlay({
        element: document.getElementById('popup'),
        offset: [9, 9]
      })
      
      this.map.addOverlay(this.popupOverlay)

      this.map.on('pointermove', function(e){
        var pixel = this.getEventPixel(e.originalEvent);
        var hit = this.hasFeatureAtPixel(pixel);
        this.getViewport().style.cursor = hit ? 'pointer' : '';
      })

      this.map.on('click', (event) => {
        let foundfeature = false

        this.map.forEachFeatureAtPixel(event.pixel,
            (feature, layer) => {
              foundfeature = true
              this.popupRoom = this.rooms.find(element => element.id == feature.values_.room_id)
              this.showPopup = true
              this.popupOverlay.setPosition(event.coordinate);
            },
            { layerFilter: (layer) => {
                return (layer.type === new VectorLayer().type) ? true : false;
            }, hitTolerance: 6 }
          )
          
        if (!foundfeature) this.showPopup = false
      })
    },
    switchView(showAsMap) {
      this.showAsMap = showAsMap
      
      // wait for tick so DOM has been updated 
      this.$nextTick(function () {
        this.initMap()
  
        // ToDo: optimize: load POI after every search when on map view and on first switch 
        this.loadPOI()
      })
    },
    loadPOI() {

      var iconStyle = new Style({
              image: new Icon({
                color: '#ffd500',
                anchor: [0.5, 46],
                anchorXUnits: 'fraction',
                anchorYUnits: 'pixels',
                src: '/map-marker-border.png',
              }),
            })

      let features = []

      this.mapPOIs.forEach(element => {
        let poi = new Feature({
          geometry: new Point(fromLonLat([element.location.coordinates[0], element.location.coordinates[1]])),
          room_id: element.room_id,
        })
        poi.setStyle(iconStyle)
        features.push(poi)
      })
      
      // remove previous data
      this.map.getLayers().forEach(layer => {if (layer.get('name') == 'resultlayer') this.map.removeLayer(layer) })

      // add features to source to layer
      this.map.addLayer(new VectorLayer({
        source: new VectorSource({features: features}),
        'name': 'resultlayer'
      }))

    },
    getRooms() {
    
      this.seachLoading = true
      this.rooms = []
      
      let filterValues =  {}
      filterValues['institution_id'] = {'status' : {_eq: 'published'}}
     
      if (this.searchTerm != '') filterValues['description'] = {'_contains': this.searchTerm}
      if (this.selectedTagsUsage.length > 0) filterValues['tags_usage'] = {'tags_usage_id' : {_in:this.selectedTagsUsage}}
      if (this.selectedTagsEquipment.length > 0) filterValues['tags_equipment'] = {'tags_equipment_id' : {_in:this.selectedTagsEquipment}}
      if (this.pricePerDay > 0) filterValues['rent_day'] = {_lte:this.pricePerDay}
      if (this.space > 0) filterValues['area'] = {_gte:this.space}
      
      let status = 'published'
      //if (process.env.VUE_APP_SEE_DRAFT_EXHIBITORS == 'true') status = 'draft,published'
 
      this.$client.items("room").readMany({ fields: ['*.*, tags_usage.*.*, tags_equipment.*.*'],
                                            filter: filterValues,
                                            'status': status})
          .then(data => {
            this.rooms = data.data

            this.mapPOIs = []

            // extract map marker
            this.rooms.forEach(element => {
              if(element.institution_id && element.institution_id.coordinates) {
                this.mapPOIs.push({
                  room_id: element.id,
                  location: element.institution_id.coordinates
                })
              }
            })

            this.seachLoading = false
          }).then(data => {
            // update map if necessary
            if (this.map) this.loadPOI()
          })
          .catch(error => console.error(error))
    }
  }
}
</script>

<style lang="scss">

.searchBox {
  .v-label {
    color:white !important;
  }

  .v-messages {
    color:white !important;
  }

  *::placeholder {
    color:white !important;
  }
 
}

</style>